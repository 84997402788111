const CONSTANTS = {
  // PROD
  // XONE_CASH_REPORT: 'https://apirepagypro.cash.zeebra.tech/api',
  // XONE_CASH_API: 'https://apiagypro.cash.zeebra.tech/api',
  // QA
  XONE_CASH_REPORT: 'https://apirepagypro.cash.zeebra.tech/api',
  XONE_CASH_API: 'https://apiagypro.cash.qa.zeebra.tech/api',
  // LOCAL
  // XONE_CASH_REPORT: 'http://localhost:3000/api',
  // XONE_CASH_API: 'http://localhost:3000/api',
  ECOMMERCE_FULFILLMENT_API: 'https://fulfillmentagypro.cash.zeebra.tech',
  ECOMMERCE_FULFILLMENT_GRANT_TYPE: 'client_credentials',
  ECOMMERCE_FULFILLMENT_CLIENT_ID: '1',
  ECOMMERCE_FULFILLMENT_CLIENT_SECRET: '7UwfHiDC6VB470AMPEopJPILLEzopffdLwOnGD3H',
  AGENT_ROLE_NAME: 'AGENT',
  AGENT_ROLE_ID: 1,
  TICKET_PAYMENT_PROMISE_STATUS: 'promesa_de_pago',
  TICKET_PAYMENT_IN_OFFICE_STATUS: 'pagado_en_oficina',
  WORKFLOW_ID: 1,
  CONTACTED_CALL_STATUS: {
    VALUE: 'CONTACTED',
    NAME: 'Contactado'
  },
  NOT_CONTACTED_CALL_STATUS: {
    VALUE: 'NOT_CONTACTED',
    NAME: 'No Contactado'
  },
  TRAYS: [
    'PENDIENTES',
    'REGESTION',
    'PROMESA DE PAGO',
    'PAGADO'
  ],
  REPORTS_EXTENSION: 'xls',
  REPORTS_EXTENSION_XLSX: 'xlsx',
  MANAGEMENT_COLLECTIONS_REPORT: 'GestionDeCobranza',
  COLLECTIONS_REPORT: 'Cobranza',
  BUDGET_REPORT: 'Presupuesto',
  DEFAULT_COUNTRY_ID: 1,
  ERROR_LOGIN_MESSAGE: 'Usuario o contraseña incorrecta',
  CALL_NOT_CONTACTED: 'volver_a_llamar_a_cliente_no_ubicado',
  CLIENT_NOT_LOCATED: 'cliente_no_ubicado',
  POSTPONED_PAYMENT: 'pago_postergado',
  PAYMENT_DELAY: 'retraso_de_pago',
  TICKET_STATUS: 'status',
  MONTHS: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ],
  // ubisocietas
  ENTITY_UPLOADS_CSV: [
    {
      name: 'Cartera',
      value: 'installments'
    },
    {
      name: 'Cobros',
      value: 'payments'
    }
  ]
}

export default CONSTANTS
